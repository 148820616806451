<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="订单列表"
        >
          <!-- <template slot="extra">
                        <a-button
                          class="editable-add-btn"
                          @click="handleAdd"
                          size="small"
                        >
                          新增
                        </a-button>
                      </template> -->
        </a-page-header>
      </div>
      <div ref="form">
        <a-form
          :form="form"
          layout="inline"
          @submit="handleSubmit"
          style="padding: 15px 0"
          ref="form"
        >
          <a-form-item label="订单号">
            <a-input
              v-decorator="['order_nu', { rules: [{ required: false }] }]"
            />
          </a-form-item>
          <a-form-item label="用户手机号">
            <a-input
              v-decorator="['mobile', { rules: [{ required: false }] }]"
            />
          </a-form-item>
          <a-form-item label="订单状态">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['validCode', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option value="16"> 已付款 </a-select-option>
              <a-select-option value="17"> 已完成 </a-select-option>
              <a-select-option value="13"> 无效 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="平台">
            <a-select
              placeholder="全部"
              style="width: 180px"
              v-decorator="['platform_type', { rules: [{ required: false }] }]"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option value="tb"> 淘宝 </a-select-option>
              <a-select-option value="jd"> 京东 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="日期">
            <a-range-picker
              @change="onChange"
              v-decorator="[
                'indate',
                {
                  initialValue: initialdate,
                  rules: [{ required: false }],
                },
              ]"
            >
            </a-range-picker>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :data-source="orderList"
        :pagination="false"
        :scroll="{ x: 1400, y: table_h }"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
      >
        <span slot="platform_type" slot-scope="platform_type">
          <span v-if="platform_type == 'jd'" style="color: #1890ff">京东</span>
          <span v-if="platform_type == 'tb'" style="color: #1890ff">淘宝</span>
        </span>
        <div slot="goodsInfo" slot-scope="goodsInfo, num" class="goodsInfo">
          <img :src="num.goodsInfo.imageUrl" alt="" />
          <div>
            <div>
              <a-tooltip placement="top" :title="num.skuName"
                ><p>{{ num.skuName }}</p></a-tooltip
              >
              <div>
                <span>￥{{ num.price }}</span>
                <span style="padding-left: 10px" v-if="num.canshowNum"
                  >*{{ num.skuNum }}
                </span>
              </div>
            </div>
            <p>{{ num.goodsInfo.shopName }}</p>
          </div>
        </div>
        <div
          slot="nickname"
          slot-scope="nickname, num"
          style="text-align: left"
        >
          <span>用户名：{{ num.nickname ? num.nickname : "--" }}</span
          ><br />
          <span>手机号：{{ num.mobile ? num.mobile : "--" }}</span
          ><br />
          <span
            >用户佣金：<span style="color: #1890ff"
              >{{ num.user_fee }}元</span
            ></span
          ><br />
          <span
            >用户上级佣金：<span style="color: #1890ff"
              >{{ num.user_par_fee }}元</span
            ></span
          >
        </div>
        <div slot="validCode" slot-scope="validCode">
          <span v-if="validCode == '13'">无效</span>
          <span v-if="validCode == '16'">已付款</span>
          <span v-if="validCode == '17'">已完成</span>
        </div>
        <div slot="orderTime" slot-scope="orderTime, num" class="orderTime">
          <span>下单时间：{{ num.orderTime ? num.orderTime : "--" }}</span
          ><br />
          <span>完成时间：{{ num.finishTime ? num.finishTime : "--" }}</span
          ><br />
          <span>结算时间：{{ num.payMonth ? num.payMonth : "--" }}</span>
        </div>
        <div slot="is_pay" slot-scope="is_pay">
          <span v-if="is_pay == '1'">已结算</span>
          <span v-if="is_pay == '0'">未结算</span>
        </div>
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
          :item-render="itemRender"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      initialdate: null,
      startTime: "",
      endTime: "",
      columns: [
        {
          title: "订单号",
          key: "order_nu",
          dataIndex: "order_nu",
          align: "center",
          width: "110px",
        },
        {
          title: "平台",
          key: "platform_type",
          dataIndex: "platform_type",
          scopedSlots: { customRender: "platform_type" },
          align: "center",
        },
        {
          title: "商品信息",
          key: "goodsInfo",
          dataIndex: "goodsInfo",
          scopedSlots: { customRender: "goodsInfo" },
          align: "center",
          width: "270px",
        },
        {
          title: "订单状态",
          key: "validCode",
          dataIndex: "validCode",
          align: "center",
          scopedSlots: { customRender: "validCode" },
        },
        {
          title: "用户信息",
          key: "nickname",
          dataIndex: "nickname",
          scopedSlots: { customRender: "nickname" },
          align: "center",
          width: "170px",
        },
        {
          title: "时间",
          key: "orderTime",
          dataIndex: "orderTime",
          align: "center",
          scopedSlots: { customRender: "orderTime" },
          width: "220px",
        },
        {
          title: "预估佣金(元)",
          key: "estimateFee",
          dataIndex: "estimateFee",
          align: "center",
        },
        {
          title: "实际佣金(元)",
          key: "actualFee",
          dataIndex: "actualFee",
          align: "center",
        },
        // {
        //   title: "用户佣金(元)",
        //   key: "user_fee",
        //   dataIndex: "user_fee",
        //   align: "center",
        // },
        // {
        //   title: "用户上级佣金(元)",
        //   key: "user_par_fee",
        //   dataIndex: "user_par_fee",
        //   align: "center",
        // },
        {
          title: "剩余佣金(元)",
          key: "sur_fee",
          dataIndex: "sur_fee",
          align: "center",
        },
        {
          title: "推广位ID",
          key: "positionId",
          dataIndex: "positionId",
          align: "center",
          width: "96px",
        },
        {
          title: "是否结算",
          key: "is_pay",
          dataIndex: "is_pay",
          scopedSlots: { customRender: "is_pay" },
          align: "center",
          fixed: "right",
        },
      ],
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      orderList: [],
    };
  },
  mounted() {
    //默认日期
    var d = new Date();
    var d1 = new Date();
    d1 = new Date(d1.getTime() - 7 * 24 * 60 * 60 * 1000);
    var early =
      d1.getFullYear() +
      "-" +
      (d1.getMonth() + 1 > 9 ? d1.getMonth() + 1 : "0" + (d1.getMonth() + 1)) +
      "-" +
      (d1.getDate() > 9 ? d1.getDate() : "0" + d1.getDate());
    var now =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) +
      "-" +
      (d.getDate() > 9 ? d.getDate() : "0" + d.getDate());
    this.initialdate = [moment(early, "YYYY-MM-DD"), moment(now, "YYYY-MM-DD")];
    this.startTime = early;
    this.endTime = now;
    window.addEventListener("resize", () => this.changeTableH(), true);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        60;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    // 日期切换
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      data.startTime = that.startTime;
      data.endTime = that.endTime;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/order/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var orderList = res.data.data.list;
            for (var i = 0; i < orderList.length; i++) {
              if (orderList[i].platform_type == "tb") {
                var str = "件商品";
                if (orderList[i].skuName.indexOf(str) > 0) {
                  orderList[i].canshowNum = false;
                } else {
                  orderList[i].canshowNum = true;
                }
              } else {
                orderList[i].canshowNum = true;
              }
            }
            that.changeTableH();
            that.orderList = orderList;
            that.total_num = res.data.data.hsaMore
              ? res.data.data.page * data.limit + 1
              : res.data.data.page * data.limit;
            that.currentPage = res.data.data.page;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.goodsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
}
.goodsInfo img {
  width: 80px;
  height: 80px;
}
.goodsInfo > div {
  width: 165px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 80px;
}
.goodsInfo > div > div {
  width: 165px;
  text-align: left;
}
/* .goodsInfo>div>div>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
} */
.goodsInfo > div > div span {
  color: #1890ff;
}
.goodsInfo p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
.orderTime {
  text-align: left;
}
/deep/.ant-pagination .ant-pagination-item {
  display: none;
}
</style>